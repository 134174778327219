import React from 'react';
import { useRecoilValue } from 'recoil';
import { recoilLoginState, recoilUserData } from '../../RecoilStore';

export default function MenuTemplate(props:any) {

  const isLogin = useRecoilValue(recoilLoginState);
  const userData = useRecoilValue(recoilUserData);

  const alertLogin = () => {
    alert('로그인이 필요합니다.');
    props.navigate('/login');
  }

  const alertRequest = () => {
    alert('본 게시판은 정회원만 접근할 수 있습니다. 등업 게시판에서 등업신청을 해주세요.');
    props.navigate('/community/graderequest');
  }

  interface SelectMenuProps {
    menuNum : number;
    title: string;
    url : string;
  }
  const SelectMenu: React.FC<SelectMenuProps> = ({ menuNum, title, url}) => {
    return (
      <div onClick={()=>{
        if (isLogin) {
          if (userData.grade === '정회원') {
            props.navigate(`/retreat${url}`)
          } else {
            alertRequest();
          }
         } else {
          alertLogin();
         }
      }}
        className={parseInt(props.currentNum) === menuNum ? "subpage__menu__item subpage__menu__item--on" : "subpage__menu__item"}>
        {title}
      </div>
    )    
  };

  interface SelectSubMenuProps {
    menuSubNum : number;
    title: string;
    url : string;
  }
  const SelectSubMenu: React.FC<SelectSubMenuProps> = ({ menuSubNum, title, url}) => {
    return (
      <div onClick={()=>{
        if (isLogin) {
          if (userData.grade === '정회원') {
            props.navigate(`/retreat${url}`)
          } else {
            alertRequest();
          }
         } else {
          alertLogin();
         }
      }}
        className={parseInt(props.currentSubNum) === menuSubNum ? "subpage__menu__item__sub subpage__menu__item--on__sub" : "subpage__menu__item__sub"}>
        {title}
      </div>
    )    
  };

  return (
    <div className="subpage__menu">
      <div className="subpage__menu__title">수련회</div>
      
      <div className="subpage__menu__list menu__desktop">
        <SelectMenu title='수련회장소' menuNum={1} url={'/'}/>
        {
          (props.currentNum === 1 || props.currentNum === '1') &&
          <div className="subpage__menu__list__sub">
            <SelectSubMenu title='서울/경기도' menuSubNum={1} url={'/1sk'}/>
            <SelectSubMenu title='강원도' menuSubNum={2} url={'/2kw'}/>
            <SelectSubMenu title='대전/충청도' menuSubNum={3} url={'/3dc'}/>
            <SelectSubMenu title='광주/전라도' menuSubNum={4} url={'/4kj'}/>
            <SelectSubMenu title='대구/부산/경상도' menuSubNum={5} url={'/5dbk'}/>
            <SelectSubMenu title='제주도' menuSubNum={6} url={'/6jeju'}/>
          </div>
        }
        <SelectMenu title='기독연합수련회' menuNum={2} url={'/unite'}/>
        {
          (props.currentNum === 2 || props.currentNum === '2') &&
          <div className="subpage__menu__list__sub">
            <SelectSubMenu title='2025겨울' menuSubNum={7} url={'/202501'}/>
            <SelectSubMenu title='2024여름' menuSubNum={8} url={'/202402'}/>
            <SelectSubMenu title='2024겨울' menuSubNum={9} url={'/202401'}/>
          </div>
        }
        <SelectMenu title='수련회강사' menuNum={3} url={'/casting'}/>
        <SelectMenu title='등록요청' menuNum={4} url={'/request'}/>
      </div>

      <div className="subpage__menu__list menu__mobile">
        {
          (props.currentNum === 1 || props.currentNum === '1') &&
          <>
            <SelectMenu title='수련회장소' menuNum={1} url={'/'}/>
            <div className="subpage__menu__list__sub">
              <SelectSubMenu title='서울/경기도' menuSubNum={1} url={'/1sk'}/>
              <SelectSubMenu title='강원도' menuSubNum={2} url={'/2kw'}/>
              <SelectSubMenu title='대전/충청도' menuSubNum={3} url={'/3dc'}/>
              <SelectSubMenu title='광주/전라도' menuSubNum={4} url={'/4kj'}/>
              <SelectSubMenu title='대구/부산/경상도' menuSubNum={5} url={'/5dbk'}/>
              <SelectSubMenu title='제주도' menuSubNum={6} url={'/6jeju'}/>
            </div>
          </>
        }
        {
          (props.currentNum === 2 || props.currentNum === '2') &&
          <>
           <SelectMenu title='기독연합수련회' menuNum={2} url={'/unite'}/>
            <div className="subpage__menu__list__sub">
              <SelectSubMenu title='2025겨울' menuSubNum={7} url={'/202501'}/>
              <SelectSubMenu title='2024여름' menuSubNum={8} url={'/202402'}/>
              <SelectSubMenu title='2024겨울' menuSubNum={9} url={'/202401'}/>
            </div>
          </>
        }
      </div>

    </div>
  )
}



