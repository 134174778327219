import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PlaceAll from './Place/PlaceAll';
import Place1sk from './Place/Place1sk';
import Place2kw from './Place/Place2kw';
import Place3dc from './Place/Place3dc';
import Place4kj from './Place/Place4kj';
import Place5dbk from './Place/Place5dbk';
import Place6jeju from './Place/Place6jeju';
import Detail from './Place/Detail';
import UniteAll from './Unite/UniteAll';
import UniteDetail from './Unite/UniteDetail';
import CastingAll from './Casting/CastingAll';
import CastingDetail from './Casting/CastingDetail';
import RequestMain from './Request/RequestMain';
import Unite202401 from './Unite/Unite202401';
import Unite202402 from './Unite/Unite202402';
import Unite202501 from './Unite/Unite202501';

export default function MainPlace() {

  return (
    <div>
      <Routes>
        <Route path="/" element={<PlaceAll/>}/>
        <Route path="/1sk" element={<Place1sk/>}/>
        <Route path="/2kw" element={<Place2kw/>}/>
        <Route path="/3dc" element={<Place3dc/>}/>
        <Route path="/4kj" element={<Place4kj/>}/>
        <Route path="/5dbk" element={<Place5dbk/>}/>
        <Route path="/6jeju" element={<Place6jeju/>}/>
        <Route path="/placedetail" element={<Detail/>}/>
        <Route path="/unite" element={<UniteAll/>}/>
        <Route path="/202401" element={<Unite202401/>}/>
        <Route path="/202402" element={<Unite202402/>}/>
        <Route path="/202501" element={<Unite202501/>}/>
        <Route path="/unitedetail" element={<UniteDetail/>}/>
        <Route path="/casting" element={<CastingAll/>}/>
        <Route path="/castingdetail" element={<CastingDetail/>}/>
        <Route path="/request" element={<RequestMain/>}/>
      </Routes>
    </div>
  );
}

